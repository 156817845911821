import React, { Component } from 'react'

import '../css/Intro.scss'

export default class Intro extends Component {
  render() {
    const { introContent } = this.props
    return (
      <div className="research-intro">
        {introContent}
        {/* <br /> */}
        <p>
          <b>
            We welcome creative, collaborative, and cool people to join the
            team!
          </b>{' '}
          If you are looking for post-docs or visiting research positions,
          please contact Haijun via email directly. For undergraduate interns,{' '}
          <a
            href="https://forms.gle/wEg12NrduEJpDWEo9"
            target="_blank"
            rel="noopnener noreferrer"
          >
            apply here
          </a>
          !
        </p>

        <p
          style={{
            border: '1.5px solid #aaa',
            backgroundColor: '#fafafa',
            padding: '1rem',
            borderRadius: '9px',
          }}
        >
          <b>
            We are looking for{' '}
            <span
              style={{
                fontSize: '1.3rem',
                fontFamily: 'EB Garamond',
                fontStyle: 'italic',
                fontKerning: 'none',
                // fontWeight: 500,
                position: 'relative',
                color: '#666',
                borderBottom: '2px dotted #999',
                paddingBottom: '2px',
                cursor: 'help',
              }}
              onMouseEnter={() => {
                const tooltip = document.getElementById('intro-tooltip')
                tooltip.style.opacity = '1'
                tooltip.style.transform = 'translateX(-50%) translateY(0)'
              }}
              onMouseLeave={() => {
                const tooltip = document.getElementById('intro-tooltip')
                tooltip.style.opacity = '0'
                tooltip.style.transform = 'translateX(-50%) translateY(10px)'
              }}
            >
              strong
              <span
                id="intro-tooltip"
                style={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(10px)',
                  backgroundColor: '#fff',
                  padding: '12px',
                  borderRadius: '5px',
                  width: '15rem',
                  fontSize: '0.8rem',
                  fontWeight: '400',
                  lineHeight: '1.5',
                  textAlign: 'center',
                  outline: '1px solid #eee',
                  boxShadow: 'rgba(100, 100, 100, 0.25) 0px 4px 24px 0px',
                  zIndex: 1000,
                  pointerEvents: 'none',
                  userSelect: 'none',
                  opacity: 0,
                  transition: 'opacity 0.2s, transform 0.2s',
                }}
              >
                with exceptional engineering and interaction design skills and a
                vision of how future interfaces should be
              </span>
            </span>{' '}
            PhD students to join the lab in Fall 2025.
          </b>{' '}
          If you are interested in applying to the lab, please apply through the{' '}
          <a href="https://cogsci.ucsd.edu/graduates/phd-program/admissions/index.html">
            Cognitive Science PhD program
          </a>{' '}
          (deadline Dec 4, 2024). We do not plan to recruit from the CSE
          department this year. More info for PhD applicants can be found{' '}
          <a href="/join">here</a>.
        </p>

        <div className="tags">
          <p>
            <span className="new-tag">NEW</span>
            <a href="/foundation">
              Talk: Redesigning the Information Space to Unleash the Power of AI
            </a>
          </p>
          <p>
            <span className="new-tag">NEW</span>
            <a href="/ai">Latest human-AI interaction research.</a>
          </p>
        </div>
      </div>
    )
  }
}
